<template>
  <div class="order-page">
    <div class="order-top">
      <div class="flex-center-between info-line">
        <div class="title">{{i18n.t('order.label1')}}</div>
        <div class="money">$ {{parseFloat(userMoney).toFixed(2)}}</div>
      </div>
      <div class="flex-center-between info-line">
        <div class="desc">{{i18n.t('order.label2')}}</div>
        <div class="label">{{i18n.t('order.label3')}}($)</div>
      </div>

      <div class="tabs">
        <Tabs  @click="changeTab">
          <Tab v-for="(item, i) in tabList" :key="i" :title="item"></Tab>
        </Tabs>
      </div>

    </div>
    <div class="order-list">
      <List
        v-if="orderDataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="i18n.t('common.noMore')"
        @load="onLoad">
        <div class="list" v-for="(item, i) in orderDataList" :key="i">
          <div class="flex-center-between">
            <div class="info">
              <div>{{i18n.t('order.label4')}}: {{item.CreatedAt}}</div>
              <div>{{i18n.t('order.label5')}}: {{item.OrderSn}}</div>
            </div>
            <div class="status" :class="`status-${item.Status}`"></div>
          </div>
          <div class="goods-info flex-center-between">
            <div class="icon">
              <img :src="`/upload/${item.Goods.ImgUrl}`" alt="">
            </div>
            <div class="flex-column-center-between detail">
              <div class="title">{{item.Goods.GoodsName}}</div>
              <div class="flex-center-between money">
                <div>${{parseFloat(item.Price).toFixed(2)}}</div>
                <div>X 1</div>
              </div>
            </div>
          </div>
          <div class="flex-center-between info-line"> 
            <div class="label">{{i18n.t('order.label6')}}</div>
            <div class="count">${{parseFloat(item.Price).toFixed(2)}}</div>
          </div>
          <div class="flex-center-between info-line"> 
            <div class="label">{{i18n.t('order.label7')}}</div>
            <div class="count">${{parseFloat(item.EarnCommission).toFixed(2)}}</div>
          </div>
          <div class="flex-center-between info-line"> 
            <div class="label">{{i18n.t('order.label8')}}</div>
            <div class="count green">${{parseFloat(item.Price + item.EarnCommission).toFixed(2)}}</div>
          </div>

          <div class="btn" v-if="item.Status == 2">
            <Button @click="showDetail(item)">{{i18n.t('order.btn1')}}</Button>
          </div>
        </div>
      </List>

      <div class="empty-box" v-if="isInit && orderDataList.length == 0">{{i18n.t('common.empty')}}</div>
    </div>

    <Popup v-model:show="showModal" class="popup-bg">
      <div class="order-popup">
        <div class="list">
          <div class="flex-center-between">
            <div class="info">
              <div>{{i18n.t('order.label4')}}: {{detailObj.CreatedAt}}</div>
              <div>{{i18n.t('order.label5')}}: {{detailObj.OrderSn}}</div>
            </div>
            <div class="status" :class="`status-1`"></div>
          </div>
          <div class="goods-info flex-center-between">
            <div class="icon">
              <img :src="`/upload/${detailObj.Goods.ImgUrl}`" alt="">
            </div>
            <div class="flex-column-center-between detail">
              <div class="title">{{detailObj.Goods.GoodsName}}</div>
              <div class="flex-center-between money">
                <div>${{detailObj.Goods.Price}}</div>
                <div>X 1</div>
              </div>
            </div>
          </div>
          <div class="flex-center-between info-line"> 
            <div class="label">{{i18n.t('order.label6')}}</div>
            <div class="count">${{detailObj.Price}}</div>
          </div>
          <div class="flex-center-between info-line"> 
            <div class="label">{{i18n.t('order.label7')}}</div>
            <div class="count">${{detailObj.EarnCommission}}</div>
          </div>
          <div class="flex-center-between info-line"> 
            <div class="label">{{i18n.t('order.label8')}}</div>
            <div class="count green">${{detailObj.Price + detailObj.EarnCommission}}</div>
          </div>
        </div>
        <div class="btn flex-center">
          <Button class="yellow" @click="cancel">{{i18n.t('order.btn2')}}</Button>
          <Button class="blue" @click="submit">{{i18n.t('order.btn3')}}</Button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Tabs, Tab, List, Popup } from 'vant'
import { orderList, taskSubmit  } from '@/api/task'
import { userInfo  } from '@/api/user'
import i18n from "@/i18n";
export default {
  components: {
    Tabs,
    Tab,
    List,
    Popup
  },
  data() {
    return {
      goodsUrl: require('../../assets/imgs/goods.jpg'),
      userMoney: '0.00',
      tabList: [i18n.t("order.label9"), i18n.t("order.label10"), i18n.t("order.label11")],
      page: {
        page: 1,
        limit: 10
      },
      activeIndex: 0,
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
      orderDataList: [],
      showModal: false,
      detailObj: {
        Goods: {}
      }
    }
  },
  mounted() {
    this.init()
    this.initUser()
  },
  methods: {
    initUser() {
      userInfo().then(res => {
        this.userMoney = res.data.UserAccount.Balance
      })
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    changeTab(i) {
      this.activeIndex = i
      this.page.page = 1
      this.orderDataList = []
      this.finished = false
      this.isInit = false
      this.init()
    },
    init(isAdd) {
      orderList({
        page: this.page.page,
        limit: this.page.limit,
        Status: this.activeIndex ? this.activeIndex : ''
      }).then(res => {
        if (res.data.Items) {
          if (isAdd) {
            this.orderDataList = this.orderDataList.concat(res.data.Items)
          } else {
            this.orderDataList = res.data.Items
          }
          this.totalPage = res.data.Pagination.totalPage
          if (this.totalPage <= this.page.page) {
             this.finished = true;
          }
        }
        this.isInit = true
        this.loading = false
        
      })
    },
    showDetail(data) {
      this.detailObj = data
      this.showModal = true
    },
    cancel() {
      this.showModal = false
    },
    submit() {
      let param = {
        Id: this.detailObj.ID
      }
      taskSubmit(param).then(res => {
        if (res.code == 0) {
          this.showModal = false
          this.page.page = 1
          this.init()
        } else {
          this.$toast(res.msg)
        }
      })
    }
  }
}
</script>