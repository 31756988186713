import fetch from "../utils/fetch";
import qs from "qs";
// 创建订单
export function createOrder(data) {
  return fetch({
    url: '/api/mobile/order-create',
    // url: '/api/mobile/order/calculate',
    method: 'Post',
    data: data
  });
}

// 任务提交
export function taskSubmit(data) {
  return fetch({
    url: '/api/mobile/order-pay',
    method: 'Post',
    data: data
  });
}
// 订单列表
export function orderList(data) {
  return fetch({
    url: '/api/mobile/order-list',
    method: 'Post',
    data: qs.stringify(data)
  });
}